import React from 'react';

const PDF = () => {

    return (
        <div>
            <a className={"underline text-orange-accent"} href="widerrufsformular.pdf" download>Vorlage Widerrufsformular</a>
        </div>
    );
}

export default PDF;

